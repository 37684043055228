import React, { useContext, useEffect } from 'react'
import { CustomerDetailContext } from '../../contexts/CustomerDetailContext';
import { EditSlaContext } from '../../contexts/EditSlaContext';
import { LoginContext } from '../../contexts/LoginContext';
import { MainContext } from '../../contexts/MainContext';
import "./EditSlaFile.css"

export default function EditSlaFile() {
    const {
        slaId,
        documents,
        getDocumentsTrigger,
        setGetDocumentsTrigger,
        file,
        setFile,
        importLoader,
        setDocuments,
        setImportLoader,
    toEditSla } = useContext(EditSlaContext);
    const { token, user, roles } = useContext(LoginContext)
    const { AddAlert } = useContext(MainContext);

    function ReadUploadFile(e) {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    function DownloadDocuments(documentId) {
        if ( toEditSla?.slaType === "SLA" && roles.some(role => role === "SlaRead") ||
        toEditSla?.slaType === "UC" && roles.some(role => role === "UcRead") ||
        toEditSla?.slaType === "OLA" && roles.some(role => role === "OlaRead") ||
        toEditSla?.slaType === "OKR" && roles.some(role => role === "OkrRead") ) {
            fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_DOCUMENT_DOWNLOAD_DOCUMENT + "?DocumentId=" + documentId, {
                method: "GET",
            })
                .then((res) => {
                    if (res.ok && res.status === 200) {
                        const disposition = res.headers.get('content-disposition');
                        const filename = disposition ? disposition.split(';')[1].split('filename=')[1].trim().replace(/\"/gi, '') : 'downloaded_file.pdf'; // Extract filename from response headers
                        return res.blob().then(blob => ({ blob, filename }));
                    } else {
                        throw new Error('Network response was not ok.');
                    }
                })
                .then(({ blob, filename }) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename; // İndirilen dosyanın adını belirle
                    document.body.appendChild(a); // DOM'a ekleyerek çalışmasını sağla
                    a.click();
                    window.URL.revokeObjectURL(url); // Belleği temizle
                })
                .catch((err) => console.error(err));
        }
        else {
            AddAlert("warning", "You do not have authorization for this operation.")
        }
    }
    function handleUploadClick(documentName) {
            if (!file) {
                return;
            }
            setImportLoader(true)
            const formData = new FormData();
            formData.append("document", file);
            formData.append("slaId", slaId);
            formData.append("accountId", user.id);
            formData.append("documentName", documentName);
            fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_DOCUMENT_UPLOAD_DOCUMENT, {
                method: "POST",
                body: formData,
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.ok) {
                        setImportLoader(false)
                        setGetDocumentsTrigger(!getDocumentsTrigger);
                        AddAlert("success", "Upload Successful")
                        res.json();
                        return res;
                    }
                    throw res;
                })
                .catch((err) => {
                    setImportLoader(false)
                    setGetDocumentsTrigger(!getDocumentsTrigger);
                    AddAlert("error", "Upload Failed")
                    console.error(err)
                });
    }
    useEffect(() => {
        if (slaId) {
            if (roles.some(role => role === "ServiceCreate")) {
                fetch(
                    process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_DOCUMENT_GET_DOCUMENT + "?SlaId=" + slaId + "&PageNumber=1&PageSize=999",
                    {
                        method: "GET",
                        headers: {
                            "accept": "application/json",
                            "Authorization": "Bearer " + token,
                        },
                    }
                )
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        }
                        throw res;
                    })
                    .then((data) => {
                        if (data?.documents) {
                            setDocuments(data.documents)
                        }
                        else {
                            setDocuments([])
                        }
                        return data;
                    })
                    .catch((error) => {
                        setDocuments([])
                        console.log(error);
                    });
            }
            else {
                AddAlert("warning", "You do not have authorization for this operation.")
            }
        }

    }, [getDocumentsTrigger, slaId]);

    return (
        <div className='edit-sla-file-container'>
            <div className='file-list-table-parent'>
                <label className='table-header'>Documents</label>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Document Name
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {documents.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {item.documentName}
                                </td>
                                <td>
                                    <button onClick={() => { DownloadDocuments(item.documentId) }} className='add-service-button'>
                                        Download
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="upload-document-parent">
                    <input
                        type="file"
                        id="file"
                        // accept=".xlsx"
                        required="required"
                        // onChange={handleFileChange}
                        onChange={ReadUploadFile}
                    />
                    <div style={{ fontSize: ".7vw" }}>{file && file.name + " " + file.type}</div>
                    <button className="upload-btn" onClick={() => handleUploadClick(file?.name)} style={{ pointerEvents: importLoader === true ? "none" : "all" }}>
                        {importLoader === true ? <div className="loader"></div> : "Upload"}
                    </button>
                </div>
            </div>

        </div>
    )
}
