import React, { useState, useRef, useContext } from "react";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { NewAgreementContext } from "./NewAgreementContext";

export const CopySlaContext = React.createContext();

const CopySlaContextProvider = (props) => {
    const { token, user, roles } = useContext(LoginContext)

    const { AddAlert } = useContext(MainContext);
    const {agreementTypes} = useContext(NewAgreementContext)

    function CreateDate() {
        var date = new Date();

        var seconds = date.getSeconds();
        var minutes = date.getMinutes();
        var hour = date.getHours();

        var year = date.getFullYear();
        var month = date.getMonth(); // beware: January = 0; February = 1, etc.
        var day = date.getDate();

        var milliSeconds = date.getMilliseconds();
        return (
            String(year) +
            "." +
            String(month) +
            "." +
            String(day) +
            "." +
            String(hour) +
            "." +
            String(minutes) +
            "." +
            String(seconds) +
            "." +
            String(milliSeconds)
        );
    }
/////////////////////////////////////////////////////////////////////////////////////
    async function CreateSla(slaName, serviceRecipientGuid, contractTypeId, companyGroupId, slaStartDate, slaEndDate) {


        const slaTypeName = agreementTypes.find(item =>
            item.id === contractTypeId
          )?.type
      
          if (( slaTypeName === "SLA" && roles.some(role => role === "SlaCreate")) ||
                (slaTypeName === "UC" && roles.some(role => role === "UcCreate")) ||
                (slaTypeName === "OLA" && roles.some(role => role === "OlaCreate")) ||
                (slaTypeName === "OKR" && roles.some(role => role === "OkrCreate")))
         {
        return new Promise((resolve) => {
            fetch(process.env.REACT_APP_BASE_URL_SLM+process.env.REACT_APP_CREATE_SLA, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify({
                    name: slaName !== "" ? slaName : "sla-" + CreateDate(),
                    accountId: user.id,
                    companyGroupId: companyGroupId,
                    companyId: serviceRecipientGuid,
                    slaTypeId: contractTypeId,
                    startDate: slaStartDate,
                    endDate: slaEndDate
                }),
            })
                .then((res) => {
                    if (res.ok) {
                        return res.json();
                    }
                    throw res;
                })
                .then((data) => {
                    localStorage.setItem("slaGuid", JSON.stringify(data.guid));
                    AddAlert("success", "Copy SLA is Succesful")
                    return data;
                })
                .then((data) => {
                    resolve(true);
                    return data;
                })
                .catch((error) => {
                    AddAlert("fail", "Copy SLA is Fail")
                    console.log(error.json());
                });
        });
    }
        else{
            AddAlert("warning", "You do not have authorization for this operation.")
          }
    }
/////////////////////////////////////////////////////////////////////////////////////
    async function  CopyKpi(kpiId) {
        return new Promise((resolve) => {
                fetch(process.env.REACT_APP_BASE_URL_SLM+process.env.REACT_APP_ADD_KPI_TO_SLA, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "accept": "application/json",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify({
                        kpiId: kpiId,
                        slaId: JSON.parse(localStorage.getItem("slaGuid")),
                        accountId: user.id
                    }),
                })
                    .then((res) => {
                        if (res.ok) {
                            AddAlert("success", "Copy KPI to SLA is Succesful")
                            return res;
                        }
                        throw res;
                    })
                    .then((data) => {
                        resolve(true);
                        return data;
                    })
                    .catch((error) => {
                        AddAlert("error", "Copy KPI to SLA is fail")
                        console.log(error);
                    })
        });    
    }
/////////////////////////////////////////////////////////////////////////////////////
    async function CopySla(slaName, serviceRecipientGuidList, contractTypeId, kpis, companyGroup, slaStartDate, slaEndDate) {
        const promisesToAwait = [];
        for (let j = 0; j < serviceRecipientGuidList.length; j++) {
            promisesToAwait.push(await CreateSla(slaName, serviceRecipientGuidList[j].id, contractTypeId,companyGroup.companyGroupId,slaStartDate, slaEndDate));
            for (let i = 0; i < kpis.length; i++) {
                promisesToAwait.push(await CopyKpi(kpis[i]));
            }
        }
        const responses = await Promise.all(promisesToAwait);
    }
/////////////////////////////////////////////////////////////////////////////////////
    return (
        <CopySlaContext.Provider value={{
            CopySla
        }}>
            {props.children}
        </CopySlaContext.Provider>
    )
}
export default CopySlaContextProvider;