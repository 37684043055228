import React, { useContext } from "react";
import { NewAgreementContext } from "./NewAgreementContext";
import { ChatbotContext } from "./ChatbotContext";
import { MainContext } from "./MainContext";
import { LoginContext } from "./LoginContext";
import { NewSlaMetaDataContext } from "./NewSlaMetaDataContext";

export const BasketContext = React.createContext();

const BasketContextProvider = (props) => {
  const { AddAlert } = useContext(MainContext);
  const { token, user, roles } = useContext(LoginContext)
  const { metaDataType, formik, oldSlaFormik, withoutSlaFormik } = useContext(NewSlaMetaDataContext)
  const {
    allChosen,
    setAllChosen,
    targetType,
  } = useContext(NewAgreementContext);
  const { setChatbotData, } = useContext(ChatbotContext)

  const {agreementTypes} = useContext(NewAgreementContext)

  function TotalMonthNumber(start, end) {
    if (parseInt(end[0]) > parseInt(start[0])) {
      return (
        parseInt(end[1]) -
        parseInt(start[1]) +
        ((parseInt(end[0]) - parseInt(start[0])) * 12) + 1
      );
    } else if (parseInt(end[0]) === parseInt(start[0])) {

      return parseInt(end[1] - parseInt(start[1])) + 1;

    }
  }

  function HandleChangeKpiName(index, name) {
    let temp = [...allChosen]
    temp[index].kpiName = name
    setAllChosen(temp)
  }

  function Delete(index) {
    let newArr = [...allChosen];
    newArr.splice(index, 1);
    setAllChosen(newArr);
  }

  function Edit(index) {
    let newArr = [...allChosen];
    newArr[index].flow[newArr[index].flow.length - 1].answer = undefined;//to delete answer of "do you wanna save?"
    setChatbotData(newArr[index].flow);
    Delete(index)
  }
  function CreateDate() {
    var date = new Date();
    var seconds = date.getSeconds();
    var minutes = date.getMinutes();
    var hour = date.getHours();
    var year = date.getFullYear();
    var month = date.getMonth(); // beware: January = 0; February = 1, etc.
    var day = date.getDate();

    var milliSeconds = date.getMilliseconds();
    return (
      String(year) +
      "." +
      String(month) +
      "." +
      String(day) +
      "." +
      String(hour) +
      "." +
      String(minutes) +
      "." +
      String(seconds) +
      "." +
      String(milliSeconds)
    );
  }
  function getDate(date, mounthNumber) {
    const initialDate = date.split("-");
    let yearCounter = parseInt(
      (mounthNumber + parseInt(initialDate[1]) + parseInt(initialDate[0] * 12)) / 12
    );
    let mounthCounter = parseInt(
      (mounthNumber + parseInt(initialDate[1]) + parseInt(initialDate[0] * 12)) % 12
    );
    initialDate[1] = mounthCounter;
    initialDate[0] = yearCounter;
    if (mounthCounter === 0) {
      initialDate[1] += 12;
      initialDate[0] -= 1;
    }
    if (initialDate[1] < 10) {
      return "01-0" + String(initialDate[1]) + "-" + String(initialDate[0]);
    } else {
      return "01-" + String(initialDate[1]) + "-" + String(initialDate[0]);
    }
  }

  function CreateSla(body) {
    const slaTypeName = agreementTypes.find(item =>
      item.id === body.slaTypeId
    )?.type




    if (( slaTypeName === "SLA" && roles.some(role => role === "SlaCreate")) ||
          (slaTypeName === "UC" && roles.some(role => role === "UcCreate")) ||
          (slaTypeName === "OLA" && roles.some(role => role === "OlaCreate")) ||
          (slaTypeName === "OKR" && roles.some(role => role === "OkrCreate"))) {

      return new Promise((resolve) => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_SLA, {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8;",
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
          body: JSON.stringify(body),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw res;
          })
          .then((data) => {
            AddAlert("success", "Create SLA is Succesful")
            resolve(data.guid)
            return data;
          })
          .catch((error) => {
            AddAlert("error", "Create SLA is Fail")
            console.log(error)
          });
      });
    }
    else {
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function GetCategory(flow) {
    let result = undefined;
    for (const item of flow) {
      if (item?.botQuestionOptions?.order === 0 || item?.botQuestionOptions?.order === 1) {
        result = item.answer.id
      }
    }
    return result;
  }
  function GetWeight(flow) {
    let result = undefined;
    for (const item of flow) {
      if (item?.botQuestionOptions?.order === 3) {
        result = item.answer.value
      }
    }
    return result;
  }

  async function AddKpi(kpi, slaId) {
    const kpiName = kpi.kpiName !== "" ? kpi.kpiName : "kpi-" + CreateDate()
    const priorityId = kpi.flow.find(item => item.botQuestionOptions.order === 2).answer.id
    const kpiCategoryId = GetCategory(kpi.flow)
    const weight = GetWeight(kpi.flow)
    let totalMonthNumber = 0
    let bodyOfCreateKpi = {}
    let startDateForTargetDate = ""
    const targetTypeId = targetType.find(item => item.name === "Minimum")?.guid

    if (metaDataType === "old") {
      totalMonthNumber = TotalMonthNumber(
        oldSlaFormik.values.startDate.split("-"),
        oldSlaFormik.values.endDate.split("-"));
      bodyOfCreateKpi = {
        name: kpiName,
        priorityGuid: priorityId,
        kpiCategoryGuid: kpiCategoryId,
        accountGuid: user.id,
        companyGroupGuid: oldSlaFormik.values.companyGroupOfSelectedSla
      }
      startDateForTargetDate = oldSlaFormik.values.startDate

    }
    else if (metaDataType === "new") {
      totalMonthNumber = TotalMonthNumber(
        formik.values.startDate.split("-"),
        formik.values.endDate.split("-")
      );
      bodyOfCreateKpi = {
        name: kpiName,
        priorityGuid: priorityId,
        kpiCategoryGuid: kpiCategoryId,
        accountGuid: user.id,
        companyGroupGuid: formik.values.selectedCompanyGroup
      }
      startDateForTargetDate = formik.values.startDate
    }
    else {//if without sla
      totalMonthNumber = TotalMonthNumber(
        withoutSlaFormik.values.startDate.split("-"),
        withoutSlaFormik.values.endDate.split("-")
      );
      bodyOfCreateKpi = {
        name: kpiName,
        priorityGuid: priorityId,
        kpiCategoryGuid: kpiCategoryId,
        accountGuid: user.id,
        companyGroupGuid: withoutSlaFormik.values.selectedCompanyGroup
      }
      startDateForTargetDate = withoutSlaFormik.values.startDate
    }

    const kpiId = await CreateKpi(bodyOfCreateKpi)

    const promisesToAwait = [];

    for (let k = 0; k < totalMonthNumber; k++) {
      const targetDate = getDate(startDateForTargetDate, k)
      promisesToAwait.push(
        CreateTarget({
          accountId: user.id,
          kpiId: kpiId,
          targetTypeId: targetTypeId,
          value: 0,
          completedValue: 0,
          weight: weight,
          targetDate: targetDate,
          isPercentage: false,
        })
      );
    }
    if (slaId) {//if slaId false it means metaDataType is without sla
      promisesToAwait.push(AddKpiToSla(kpiId, slaId))
    }
    promisesToAwait.push(setAllChosen([]))
    const responses = await Promise.all(promisesToAwait);
  }

  function AddKpiToSla(kpiGuid, slaGuid) {
      return new Promise((resolve) => {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_ADD_KPI_TO_SLA, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "accept": "application/json",
            "Authorization": "Bearer " + token,
          },
          body: JSON.stringify({
            kpiId: kpiGuid,
            slaId: slaGuid,
            accountId: user.id
          }),
        })
          .then((res) => {
            if (res.ok) {
              AddAlert("success", "Adding KPI to SLA is Succesful")
              return res;
            }
            throw res;
          })
          .then((data) => {
            resolve(true);
            return data;
          })
          .catch((error) => {
            AddAlert("error", "Adding KPI to SLA is fail")
            console.log(error);
          });
      })
  }

  function CreateKpi(body) {
    if (roles.some(role => role === "KpiCreate")) {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_KPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          AddAlert("success", "Create KPI is Succesful")
          resolve(data.kpi.id);
        })
        .catch((error) => {
          AddAlert("error", "Create KPI is Fail")
          console.log(error);
        });
    })
  }
    else{
      AddAlert("warning", "You do not have authorization for this operation.")
    }
  }

  function CreateTarget(body) {
    if (roles.some(role => role === "KpiCreate")) {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_CREATE_TARGET, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          console.log(error.json());
        });
    });
  }
  else{
    AddAlert("warning", "You do not have authorization for this operation.")
  }
  }


  function VerifiedSla(body) {
    return new Promise((resolve) => {
      fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_VERIFIED_SLA, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
        })
        .then((data) => {
          resolve(true);
          return data;
        })
        .catch((error) => {
          console.log(error.json());
        });
    });
  }

  function GetDays(date) {
    const splittedDays = date.split("-")
    const year = splittedDays[0]
    const month = splittedDays[1]
    return new Date(year, month, 0).getDate();
  };

  return (
    <BasketContext.Provider value={{
      CreateDate,
      Delete,
      Edit,
      TotalMonthNumber,
      getDate,
      CreateSla,
      AddKpi,
      HandleChangeKpiName,
      GetDays,
      VerifiedSla
    }}>
      {props.children}
    </BasketContext.Provider>
  );
}

export default BasketContextProvider;