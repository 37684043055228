import React, { useContext, useState } from 'react'
import Tree from 'react-d3-tree';
import "./OrganizationTree.css"
import { useCenteredTree } from "./Helpers";
import add_ico from "../../assets/plus.png"
import confirm_ico from "../../assets/confirm.png"
import cancel_ico from "../../assets/cancel.png"
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { LoginContext } from '../../contexts/LoginContext';
import PopUpAlert from '../PopUpAlert/PopUpAlert';
import Users from './Users';
import { MainContext } from '../../contexts/MainContext';
import edit_ico from "../../assets/pencil.png"
import ReleatedAgreements from './ReleatedAgreements';

export default function OrganizationTree({ treeData, width, height }) {

    const { roles, user } = useContext(LoginContext)
    const { langFile } = useContext(MainContext);
    const [dimensions, translate, containerRef] = useCenteredTree();
    const { CreateOrganization,
        organizationNameToCreate,
        setOrganizationNameToCreate,
        selectedOrganizationGroup,
        DeleteOrganization,
        GetPositionOfOrganization,
        setTreeData,
        FindOrganizationNode,
        CreatePosition,
        DeletePosition,
        parentOrganizationIdToAddDepartment,
        setParentOrganizationIdToAddDepartment,
        parentPositionIdToAdd,
        setParentPositionIdToAdd,
        isDeleteOrganizationPopUp,
        setIsDeleteOrganizationPopUp,
        isDeletePositionPopUp,
        setIsDeletePositionPopUp,
        positionNameToCreate,
        setPositionNameToCreate,
        parentOrganizationIdToAddPosition,
        setParentOrganizationIdToAddPosition,
        showPosition,
        setShowPosition,
        idOfClickedDepartment,
        setIdOfClickedDepartment,
        isShowUsers,
        setIsShowUsers,
        userList,
        setUserList,
        GetAccounts,
        departmentOrPositionName,
        setDepartmentOrPositionName,
        initialDepth,
        clickedEditDepartment,
        setClickedEditDepartment,
        UpdateOrganization,
        isShowAgreements,
        setIsShowAgreements,
        okrList,
        GetOkr
    } = useContext(OrganizationContext)

    const nodeSize = { x: 300, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: (-nodeSize.x) / 2, overflow: "visible" };
    const containerStyles = {
        width: width,
        height: height,
        position: "relative",
    };
    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps
    }) => (
        <g>
            <foreignObject {...foreignObjectProps}>
                <div style={{
                    padding: "1vh 1vw ", display: "flex", flexDirection: "row", border: "1px solid black", position: "relative",
                    backgroundColor: nodeDatum.attributes.type === "department" ? "var(--backgroundColor3)" : "var(--backgroundColor2)",
                    borderRadius: "5px 5px 0px 0px", border: "none"
                }}>
                    {nodeDatum.attributes.type === "department" && clickedEditDepartment && clickedEditDepartment.attributes.organization.id === nodeDatum.attributes.organization.id ?
                        <React.Fragment>
                            <input id='departmentName' name='departmentName' value={clickedEditDepartment.name}
                                onChange={(e) => { let temp = { ...clickedEditDepartment }; temp.name = e.target.value; setClickedEditDepartment(temp) }}
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "var(--textColor2)",
                                    backgroundColor: "transparent",
                                    border: "1px solid grey",
                                    borderRadius: "10px",
                                    height: "3vh",
                                }}
                            ></input>
                            <img alt="confirmOrganizationAdd" className='confirmOrganizationAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "50%",
                                right: "-2vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                                transform: "translate(0%, 50%)"
                            }} onClick={() => {
                                UpdateOrganization({
                                    "accountId": user.id,
                                    "organizationId": clickedEditDepartment.attributes.organization.id,
                                    "name": clickedEditDepartment.name
                                })
                            }} src={confirm_ico}>
                            </img>
                            <img alt="cancelOrganizationAdd" className='cancelOrganizationAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "50%",
                                right: "-4vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                                transform: "translate(0%, 50%)"
                            }} src={cancel_ico} onClick={() => { setClickedEditDepartment() }}>
                            </img>
                        </React.Fragment> :
                        <React.Fragment>
                            <div onClick={toggleNode} style={{ width: "-webkit-fill-available", display: "flex", flexDirection: "column", wordWrap: "break-word" }}>
                                {nodeDatum.attributes.type === "position" ? "" : ""}
                                <h3 style={{
                                    textAlign: "center",
                                    maxWidth: "90%",
                                    color: nodeDatum.attributes.type === "department" ? "var(--textColor2)" : "var(--textColor)", fontSize: ".8vw", fontWeight: "500"
                                }}>{nodeDatum.name}</h3>
                                {
                                    nodeDatum?.attributes?.department && nodeDatum?.attributes?.type === "person" &&
                                    <h3 style={{ textAlign: "center", color: "var(--textColor)", fontSize: ".8vw", fontWeight: "400" }}>{nodeDatum?.attributes?.department}</h3>
                                }
                            </div>
                            <div onClick={toggleNode} style={{ display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center", justifyContent: "center" }}>
                                {nodeDatum.children.length > 0 && nodeDatum.__rd3t.collapsed && (
                                    <div style={{ color: nodeDatum.attributes.type === "department" ? "var(--textColor2)" : "var(--textColor)", fontSize: "1.5vw" }}>
                                        {">"}
                                    </div>
                                )}
                            </div>
                            {roles.some(role => role === "OrganizationEdit")&&
                            <div onClick={() => { setClickedEditDepartment(nodeDatum) }} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={edit_ico} alt='edit-department'
                                    style={{ display: 'flex', width: "1vw", height: "fit-content", filter: "invert(100%)", WebkitFilter: "invert(100%)", marginLeft: ".5vw" }}>
                                </img>
                            </div>
                            }
                        </React.Fragment>
                    }
                    {nodeDatum.attributes.type === "department" && (parentOrganizationIdToAddDepartment === nodeDatum?.attributes?.organization?.id ?
                        <React.Fragment>
                            <input id='departmentName' name='departmentName' placeholder={langFile.pages.Organization.enterDepartmentName} value={organizationNameToCreate}
                                onChange={(e) => setOrganizationNameToCreate(e.target.value)}
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "4vh",
                                    bottom: "-4vh",
                                    left: "0px",
                                    border: "1px solid #000",
                                    backgroundColor: "var(--backgroundColor)",
                                    borderRadius: "0px 0px 5px 5px",
                                    textAlign: "center",
                                    color: "var(--textColor)"
                                }}
                            ></input>
                            <img alt="confirmOrganizationAdd" className='confirmOrganizationAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "-3.5vh",
                                right: "-2vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                            }} onClick={() => {
                                CreateOrganization({
                                    "accountId": user.id,
                                    "code": "0",
                                    "name": organizationNameToCreate,
                                    "organizationGroupId": selectedOrganizationGroup,
                                    "organizationLevelId": null,
                                    "parentOrganizationId": parentOrganizationIdToAddDepartment
                                })
                                setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                            }} src={confirm_ico}>
                            </img>
                            <img alt="cancelOrganizationAdd" className='cancelOrganizationAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "-3.5vh",
                                right: "-4vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                            }} src={cancel_ico} onClick={() => { setParentOrganizationIdToAddDepartment(false); setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id) }}>
                            </img>
                        </React.Fragment> :
                        parentOrganizationIdToAddPosition === nodeDatum?.attributes?.organization?.id ?
                            <React.Fragment>
                                <input id='positionName' name='positionName' placeholder={langFile.pages.Organization.enterPositionName} value={positionNameToCreate}
                                    onChange={(e) => setPositionNameToCreate(e.target.value)}
                                    style={{
                                        position: "absolute",
                                        width: "100%",
                                        height: "4vh",
                                        bottom: "-4vh",
                                        left: "0px",
                                        border: "1px solid #000",
                                        backgroundColor: "var(--backgroundColor)",
                                        borderRadius: "0px 0px 5px 5px",
                                        textAlign: "center",
                                        color: "var(--textColor)"
                                    }}
                                ></input>
                                <img alt="confirmPositionAdd" className='confirmPositionAdd' style={{
                                    position: "absolute",
                                    width: "1.5vw",
                                    height: "fit-content",
                                    bottom: "-3.5vh",
                                    right: "-2vw",
                                    border: "none",
                                    filter: "invert(var(--filterRatio))",
                                    WebkitFilter: "invert(var(--filterRatio))",
                                }} onClick={() => {
                                    CreatePosition({
                                        "accountId": user.id,
                                        "code": "0",
                                        "name": positionNameToCreate,
                                        "parentPositionId": null,
                                        "positionLevelId": null,
                                        "organizationId": parentOrganizationIdToAddPosition
                                    })
                                    setShowPosition(true)
                                    setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                                }} src={confirm_ico}>
                                </img>
                                <img alt="cancelPositionAdd" className='cancelPositionAdd' style={{
                                    position: "absolute",
                                    width: "1.5vw",
                                    height: "fit-content",
                                    bottom: "-3.5vh",
                                    right: "-4vw",
                                    border: "none",
                                    filter: "invert(var(--filterRatio))",
                                    WebkitFilter: "invert(var(--filterRatio))",
                                }} src={cancel_ico} onClick={() => {
                                    setParentOrganizationIdToAddPosition(false)
                                    setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                                }}>
                                </img>
                            </React.Fragment> :
                            // roles.some(role => role === "OrganizationCreate") ?
                            <div style={{
                                width: "100%", height: "9vh", bottom: "-9vh", left: "0px", position: "absolute",
                                borderRadius: "0px 0px 5px 5px", display: "flex", flexDirection: "column", border: "1px solid #000"
                            }}>
                                <div style={{
                                    width: "100%", height: "3vh", display: "flex", flexDirection: "row",
                                    wordWrap: "break-word"
                                }}>
                                    {roles.some(role => role === "OrganizationCreate") &&
                                        <button onClick={() => {
                                            setParentOrganizationIdToAddDepartment(nodeDatum?.attributes?.organization?.id)
                                            setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                                        }}
                                            alt='add-organization' style={{
                                                display: 'flex',
                                                width: "100%", height: "100%", borderLeft: "0px", borderTop: "0px", borderBottom: "0.5px solid #000", borderRight: "0.5px solid #000",
                                                fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",

                                            }}>
                                            {langFile.pages.Organization.addSubDepartment}
                                        </button>
                                    }
                                    {roles.some(role => role === "OrganizationDelete") &&

                                        <button onClick={() => {
                                            setIsDeleteOrganizationPopUp(nodeDatum)
                                            setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                                        }}
                                            alt='add-organization' style={{
                                                display: 'flex',
                                                width: "100%", height: "100%", border: "none", borderRight: "0px", borderTop: "0px", borderBottom: "0.5px solid #000", borderLeft: "0.5px solid #000",
                                                fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                            }}>
                                            {langFile.pages.Organization.deleteDepartment}
                                        </button>
                                    }
                                </div>
                                <div style={{
                                    width: "100%", height: "3vh", display: "flex", flexDirection: "row",
                                    wordWrap: "break-word"
                                }}>
                                    {roles.some(role => role === "PositionCreate") &&

                                        <button onClick={() => {
                                            setParentOrganizationIdToAddPosition(nodeDatum?.attributes?.organization?.id)
                                            setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                                        }}
                                            alt='add-organization' style={{
                                                display: 'flex',
                                                width: "100%", height: "3vh", border: "none", borderLeft: "0px", borderTop: "0.5px solid #000", borderBottom: "0.5px solid #000", borderRight: "0.5px solid #000", borderRadius: "0px",
                                                fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                            }}>
                                            {langFile.pages.Organization.addPosition}
                                        </button>
                                    }
                                    {roles.some(role => role === "PositionRead") &&
                                        <button onClick={() => {
                                            if (!showPosition) {//showPosition false it means we click to show positions 
                                                if (!nodeDatum?.tempChildrenForPositions || nodeDatum?.tempChildrenForPositions?.length === 0) {
                                                    GetPositionOfOrganization({
                                                        "organizationId": nodeDatum?.attributes?.organization?.id,
                                                        "pageNumber": 1,
                                                        "pageSize": 999
                                                    }, true)
                                                }
                                                else {
                                                    let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                                                    if (targetNode && (targetNode?.children?.length > 0 && targetNode.children[0]?.attributes?.type === "department" ||
                                                        targetNode?.tempChildrenForPositions?.length > 0 && targetNode.tempChildrenForPositions[0]?.attributes?.type === "position")) {//when we want to show positions
                                                        let temp = targetNode.tempChildrenForPositions
                                                        targetNode.tempChildrenForPositions = targetNode.children;//to switch between department and positions
                                                        targetNode.children = temp;
                                                    }
                                                    setTreeData((prevData) => ({ ...prevData }));
                                                }
                                                setShowPosition(true)
                                            }
                                            else if (showPosition) {//showPosition true it means we click to show department 
                                                if (nodeDatum?.tempChildrenForPositions) {
                                                    let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                                                    if (targetNode && (targetNode?.tempChildrenForPositions?.length > 0 && targetNode.tempChildrenForPositions[0]?.attributes?.type === "department" ||
                                                        targetNode?.children?.length > 0 && targetNode.children[0]?.attributes?.type === "position")) {///when we want to show departments{
                                                        let temp = targetNode.children
                                                        targetNode.children = targetNode.tempChildrenForPositions;//to switch between department and positions
                                                        targetNode.tempChildrenForPositions = temp;
                                                    }
                                                    setTreeData((prevData) => ({ ...prevData }));
                                                }
                                                else {
                                                    let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                                                    if (targetNode) {///when we want to show departments{
                                                        let temp = targetNode.children
                                                        targetNode.children = [];//to switch between department and positions
                                                        targetNode.tempChildrenForPositions = temp;
                                                    }
                                                    setTreeData((prevData) => ({ ...prevData }));
                                                }
                                                setShowPosition(false)
                                            }

                                            setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)

                                        }}
                                            alt='add-organization' style={{
                                                display: 'flex',
                                                width: "100%", height: "3vh", border: "none", borderLeft: "0.5px solid #000", borderRight: "0px", borderBottom: "0.5px solid #000", borderTop: "0.5px solid #000", borderRadius: "0px",
                                                fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                            }}>
                                            {/* {showPosition && idOfClickedDepartment === nodeDatum?.attributes?.organization?.id ?
                                            langFile.pages.Organization.showDepartment
                                            :
                                            langFile.pages.Organization.showPosition
                                        } */}
                                            {
                                                nodeDatum && (nodeDatum?.children?.length > 0 && nodeDatum.children[0]?.attributes?.type === "position" ||
                                                    nodeDatum?.tempChildrenForPositions?.length > 0 && nodeDatum.tempChildrenForPositions[0]?.attributes?.type === "department") ?
                                                    langFile.pages.Organization.showDepartment :
                                                    langFile.pages.Organization.showPosition
                                            }
                                        </button>
                                    }
                                </div>
                                <div style={{
                                    width: "100%", height: "3vh", display: "flex", flexDirection: "row",
                                    wordWrap: "break-word"
                                }}>
                                     {roles.some(role => role === "OkrRead") &&
                                    <button onClick={() => {
                                         GetOkr({OwnerAccountId:false, OwnerOrganizationId:nodeDatum?.attributes?.organization?.id})
                                         setIsShowAgreements(true)
                                         setDepartmentOrPositionName(nodeDatum?.attributes?.organization?.name)
                                        ("nodeDatum",nodeDatum)
                                    }}
                                        alt='add-organization' style={{
                                            display: 'flex',
                                            width: "100%", height: "100%", border: "none", borderRight: "0px", borderTop: "0.5px solid #000", borderBottom: "0px", borderLeft: "0px",borderRadius:"0px 0px 5px 5px",
                                            fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                        }}>
                                        {langFile.pages.Organization.releatedAgreements}
                                    </button>
                                    }
                                </div>
                            </div>
                        // :
                        // <div style={{
                        //     width: "100%", height: "3vh", bottom: "-3vh", left: "0px", position: "absolute",
                        //     borderRadius: "0px 0px 5px 5px", display: "flex", flexDirection: "column", border: "1px solid #000",
                        // }}>

                        //     <button onClick={() => {
                        //         if (!showPosition) {//showPosition false it means we click to show positions 
                        //             if (!nodeDatum?.tempChildrenForPositions || nodeDatum?.tempChildrenForPositions?.length === 0) {
                        //                 GetPositionOfOrganization({
                        //                     "organizationId": nodeDatum?.attributes?.organization?.id,
                        //                     "pageNumber": 1,
                        //                     "pageSize": 999
                        //                 }, true)
                        //             }
                        //             else {
                        //                 let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                        //                 if (targetNode && (targetNode?.children?.length > 0 && targetNode.children[0]?.attributes?.type === "department" ||
                        //                     targetNode?.tempChildrenForPositions?.length > 0 && targetNode.tempChildrenForPositions[0]?.attributes?.type === "position")) {//when we want to show positions
                        //                     let temp = targetNode.tempChildrenForPositions
                        //                     targetNode.tempChildrenForPositions = targetNode.children;//to switch between department and positions
                        //                     targetNode.children = temp;
                        //                 }
                        //                 setTreeData((prevData) => ({ ...prevData }));
                        //             }
                        //             setShowPosition(true)
                        //         }
                        //         else if (showPosition) {//showPosition true it means we click to show department 
                        //             if (nodeDatum?.tempChildrenForPositions) {
                        //                 let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                        //                 if (targetNode && (targetNode?.tempChildrenForPositions?.length > 0 && targetNode.tempChildrenForPositions[0]?.attributes?.type === "department" ||
                        //                     targetNode?.children?.length > 0 && targetNode.children[0]?.attributes?.type === "position")) {///when we want to show departments{
                        //                     let temp = targetNode.children
                        //                     targetNode.children = targetNode.tempChildrenForPositions;//to switch between department and positions
                        //                     targetNode.tempChildrenForPositions = temp;
                        //                 }
                        //                 setTreeData((prevData) => ({ ...prevData }));
                        //             }
                        //             else {
                        //                 let targetNode = FindOrganizationNode(treeData, nodeDatum?.attributes?.organization?.id);
                        //                 if (targetNode) {///when we want to show departments{
                        //                     let temp = targetNode.children
                        //                     targetNode.children = [];//to switch between department and positions
                        //                     targetNode.tempChildrenForPositions = temp;
                        //                 }
                        //                 setTreeData((prevData) => ({ ...prevData }));
                        //             }
                        //             setShowPosition(false)
                        //         }
                        //         setIdOfClickedDepartment(nodeDatum?.attributes?.organization?.id)
                        //     }}
                        //         alt='add-organization' style={{
                        //             display: 'flex',
                        //             width: "100%", height: "3vh", border: "none", borderLeft: "0px", borderRight: "0px", borderBottom: "0px", borderTop: "1px solid #000", borderRadius: "0px 0px 5px 5px",
                        //             fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                        //         }}>
                        //         {/* {showPosition && idOfClickedDepartment === nodeDatum?.attributes?.organization?.id ?
                        //             langFile.pages.Organization.showDepartment
                        //             :
                        //             langFile.pages.Organization.showPosition
                        //         } */}
                        //         {
                        //             nodeDatum && (nodeDatum?.children?.length > 0 && nodeDatum.children[0]?.attributes?.type === "position" ||
                        //                 nodeDatum?.tempChildrenForPositions?.length > 0 && nodeDatum.tempChildrenForPositions[0]?.attributes?.type === "department") ?
                        //                 langFile.pages.Organization.showDepartment :
                        //                 langFile.pages.Organization.showPosition
                        //         }
                        //     </button>
                        // </div>

                    )}
                    {nodeDatum.attributes.type === "position" && (parentPositionIdToAdd === nodeDatum?.attributes?.position?.id ?
                        <React.Fragment>
                            <input id='positionName' name='positionName' placeholder={langFile.pages.Organization.enterPositionName} value={positionNameToCreate}
                                onChange={(e) => setPositionNameToCreate(e.target.value)}
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "4vh",
                                    bottom: "-4vh",
                                    left: "0px",
                                    border: "1px solid #000",
                                    backgroundColor: "var(--backgroundColor)",
                                    borderRadius: "0px 0px 5px 5px",
                                    textAlign: "center",
                                    color: "var(--textColor)"
                                }}
                            ></input>
                            <img alt="confirmPositionAdd" className='confirmPositionAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "-3.5vh",
                                right: "-2vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                            }} onClick={() => {
                                CreatePosition({
                                    "accountId": user.id,
                                    "code": "0",
                                    "name": positionNameToCreate,
                                    "parentPositionId": parentPositionIdToAdd,
                                    "positionLevelId": null,
                                    "organizationId": nodeDatum?.attributes?.position?.organizationId
                                })
                                setShowPosition(true)
                            }} src={confirm_ico}>
                            </img>
                            <img alt="cancelPositionAdd" className='cancelPositionAdd' style={{
                                position: "absolute",
                                width: "1.5vw",
                                height: "fit-content",
                                bottom: "-3.5vh",
                                right: "-4vw",
                                border: "none",
                                filter: "invert(var(--filterRatio))",
                                WebkitFilter: "invert(var(--filterRatio))",
                            }} src={cancel_ico} onClick={() => { setParentPositionIdToAdd(false) }}>
                            </img>
                        </React.Fragment> :
                        // roles.some(role => role === "UserCreate") ?

                        <div style={{
                            width: "100%", height: "6vh", bottom: "-6vh", left: "0px", position: "absolute",
                            borderRadius: "0px 0px 5px 5px", display: "flex", flexDirection: "column", border: "1px solid #000",
                        }}>
                            <div style={{
                                width: "100%", height: "3vh", display: "flex", flexDirection: "row",

                            }}>
                                {roles.some(role => role === "PositionCreate") &&
                                    <button onClick={() => {
                                        setParentPositionIdToAdd(nodeDatum?.attributes?.position?.id)
                                    }}
                                        alt='add-position' style={{
                                            display: 'flex',
                                            width: "100%", height: "100%", borderLeft: "0px", borderTop: "0px", borderBottom: "0px", borderRight: "0.5px solid #000",
                                            fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",

                                        }}>
                                        {langFile.pages.Organization.addSubPosition}
                                    </button>
                                }
                                {roles.some(role => role === "PositionDelete") &&
                                    <button onClick={() => {
                                        setIsDeletePositionPopUp(nodeDatum)
                                    }}
                                        alt='add-organization' style={{
                                            display: 'flex',
                                            width: "100%", height: "100%", border: "none", borderRight: "0px", borderTop: "0px", borderBottom: "0px", borderLeft: "0.5px solid #000",
                                            fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                        }}>
                                        {langFile.pages.Organization.deletePosition}

                                    </button>
                                }
                            </div>
                            {roles.some(role => role === "UserRead") &&
                                <button onClick={() => {
                                    GetAccounts({
                                        companyId: user.company.id,
                                        pageIndex: 1,
                                        pageSize: 999,
                                        includeAccountType: true,
                                        includeCompany: true,
                                        includeOrganizations: true,
                                        includePositions: true,
                                        positionId: nodeDatum?.attributes?.position?.id
                                    })
                                    setIsShowUsers(true)
                                    setDepartmentOrPositionName(nodeDatum?.attributes?.position?.name)
                                }}
                                    alt='get-position' style={{
                                        display: 'flex',
                                        width: "100%", height: "3vh", border: "none", borderLeft: "0px", borderRight: "0px", borderBottom: "0px", borderTop: "1px solid #000", borderRadius: "0px 0px 5px 5px",
                                        fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                                    }}>
                                    {langFile.pages.Organization.showUsers}
                                </button>
                            }
                            
                        </div>
                        // :
                        // <div style={{
                        //     width: "100%", height: "3vh", bottom: "-3vh", left: "0px", position: "absolute",
                        //     borderRadius: "0px 0px 5px 5px", display: "flex", flexDirection: "column", border: "1px solid #000",
                        // }}>
                        //     <button onClick={() => {
                        //         GetAccounts({
                        //             companyId: user.company.id,
                        //             pageIndex: 1,
                        //             pageSize: 999,
                        //             includeAccountType: true,
                        //             includeCompany: true,
                        //             includeOrganizations: true,
                        //             includePositions: true,
                        //             positionId: nodeDatum?.attributes?.position?.id
                        //         })
                        //         setIsShowUsers(true)
                        //         setDepartmentOrPositionName(nodeDatum?.attributes?.position?.name)
                        //     }}
                        //         alt='get-position' style={{
                        //             display: 'flex',
                        //             width: "100%", height: "3vh", border: "none", borderLeft: "0px", borderRight: "0px", borderBottom: "0px", borderTop: "0px solid #000", borderRadius: "0px 0px 5px 5px",
                        //             fontSize: ".8vw", backgroundColor: "var(--backgroundColor)", justifyContent: "center", alignItems: "center",
                        //         }}>
                        //         {langFile.pages.Organization.showUsers}
                        //     </button>
                        // </div>
                    )}
                </div>
            </foreignObject>
        </g>
    );
    return (
        <div style={containerStyles} ref={containerRef}>
            {isDeleteOrganizationPopUp && <PopUpAlert text={"Do you wanna delete " + isDeleteOrganizationPopUp?.name + " ?"} func={DeleteOrganization} setIsPopUp={setIsDeleteOrganizationPopUp} body={{
                organizationId: isDeleteOrganizationPopUp?.attributes?.organization?.id
            }} ></PopUpAlert>}
            {isDeletePositionPopUp && <PopUpAlert text={"Do you wanna delete " + isDeletePositionPopUp?.name + " ?"} func={DeletePosition} setIsPopUp={setIsDeletePositionPopUp}
                body={{ body: { positionId: isDeletePositionPopUp?.attributes?.position?.id }, organizationId: isDeletePositionPopUp?.attributes?.position?.organizationId }} ></PopUpAlert>}
            {isShowUsers && <Users userList={userList} department={departmentOrPositionName} setShowUsers={setIsShowUsers}></Users>}
            {isShowAgreements && <ReleatedAgreements agreementsList={okrList} department={departmentOrPositionName} setShow={setIsShowAgreements}></ReleatedAgreements>}
            
            <Tree
                data={treeData}
                translate={translate}
                nodeSize={nodeSize}
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
                orientation="vertical"
                renderCustomNodeElement={(rd3tProps) =>
                    renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                }
                pathFunc="elbow"
                // shouldCollapseNeighborNodes="True" // Komşu düğümleri otomatik olarak daraltma (varsayılan: false)
                separation={{ siblings: 1.5, nonSiblings: 2 }} // separation prop'u içindeki objeyi düzelt
                initialDepth={initialDepth}
                // dimensions={dimensions}
                enableLegacyTransitions={true}
                // transitionDuration={1500}
                depthFactor={300}
            />
        </div>
    )
}
