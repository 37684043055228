import React, { useEffect, useState, useContext } from "react";
import "../styles/Customers.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/people.png";
import trend_ico from "../assets/trend.png";
import OlaSlaPieChart from "../components/OlaSlaPieChart";
import GaugeStatistic from "../components/GaugeStatistic";
import RoseStatistic from "../components/RoseStatistic";
import { MainContext } from "../contexts/MainContext";
import { CustomersContext } from "../contexts/CustomersContext";
import right_arrow_ico from "../assets/right-arrow-2.png"
import { LoginContext } from "../contexts/LoginContext";
import SelectCompany from "../components/SelectCompany";
import delete_ico from "../assets/delete.png"
import PopUpAlert from "../components/PopUpAlert/PopUpAlert";
import Navigation from "../components/Navigation";
import { CustomerDetailContext } from "../contexts/CustomerDetailContext";

export default function Customers() {
  const { langFile, setAlertList, AddAlert } = useContext(MainContext);
  const {
    usersOfCompanyComponent,
    setUsersOfCompanyComponent,
    statisticOfCompanyComponent,
    setStatisticOfCompanyComponent,
    customers,
    setCustomer,
    isDeletePopUp,
    setIsDeletePopUp,
    DeleteCompany,
    GetAllCompany,
    companyGroupList,
    selectedCompanyGroup,
    setSelectedCompanyGroup,
    GetAllCompanyGroup,
    IsPossibleDeleteCompany
  } = useContext(CustomersContext);
  const { user, roles } = useContext(LoginContext)
  const navigate = useNavigate();
  const { selectedServiceRecipientCompany,
    setSelectedServiceRecipientCompany } = useContext(CustomerDetailContext)


  useEffect(() => {
    if (user?.company?.id) {
      GetAllCompanyGroup(user.company.id)
    }
  }, [user?.company?.id])

  useEffect(() => {
    return () => {
      // Bu kod, component unmount olduğunda (yani sayfa kapatıldığında) çalışır

    };
  }, []);

  return (
    <div className="parent-container-customers">
      <div className="customers-general-parent">
        <div
          className={
            statisticOfCompanyComponent === false
              ? "customers-general-container"
              : "customers-general-container customers-general-container-side"
          }
        >
          <div className='navigation-container'>
            <Navigation img={logo} text_1={langFile.pages.Customers.customers} text_2={langFile.pages.Customers.servicesCustomersCompanies}></Navigation>
            <SelectCompany></SelectCompany>
            <label className="selected-comp-label">
              {langFile.pages.NewCompany.selectedCompanyGroup}
            </label>
            <select
              name="companyGroup"
              id="companyGroup"
              onChange={(event) => {
                setSelectedCompanyGroup(
                  companyGroupList.find(company => company.companyGroupId === event.target.value)
                )
              }}
              value={selectedCompanyGroup?.companyGroupId}
            >
              {companyGroupList?.map((company, index) => (
                <option key={index} value={company.companyGroupId}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <table
            // className={
            //   usersOfCompanyComponent === false
            //     ? "container-customers"
            //     : "container-customers container-customers-side"
            // }
            className={"container-customers"}
          >
            <thead>
              <tr>
                <th>
                  {langFile.pages.Customers.serviceRecipientCompany}
                </th>
                <th>
                  {langFile.pages.Customers.serviceProviderCompany}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {selectedCompanyGroup?.companies?.map((customer, index) =>
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td
                      onClick={() => {
                        if(roles.some(role => role === "SlaRead") || roles.some(role => role === "OlaRead") || roles.some(role => role === "UcRead") || roles.some(role => role === "OkrRead")){
                          setSelectedServiceRecipientCompany(customer);
                          navigate("/main/customer-detail");
                        }

                      }}
                    >
                      {selectedCompanyGroup.ownerCompany.name}
                    </td>
                    <td
                      onClick={() => {
                        if(roles.some(role => role === "SlaRead") || roles.some(role => role === "OlaRead") || roles.some(role => role === "UcRead") || roles.some(role => role === "OkrRead")){
                          setSelectedServiceRecipientCompany(customer);
                          navigate("/main/customer-detail");
                        }
                      }}
                    >
                      {customer.name}
                    </td>
                    <td>
                      {roles.some(role => role === "CompanyDelete") &&
                        <div className="tooltip-container">
                          <img
                            onClick={() => {
                              IsPossibleDeleteCompany(customer.id)
                            }}
                            style={{
                              filter: "invert(var(--filterRatio))",
                              WebkitFilter: "invert(var(--filterRatio))",
                              cursor: "pointer"
                            }}
                            className="statisticButton tooltip-trigger"
                            alt="userOfCompany"
                            src={delete_ico}
                          ></img>
                          <div style={{
                            top: "0vh",
                            right: "2vw"
                          }} className="tooltip-content">Delete company</div>
                        </div>
                      }
                      {/* <div className="tooltip-container">
                        <img
                          onClick={() => {
                            setStatisticOfCompanyComponent(
                              !statisticOfCompanyComponent
                            );
                          }}
                          style={{
                            filter: "invert(var(--filterRatio))",
                            WebkitFilter: "invert(var(--filterRatio))",
                            cursor: "pointer"
                          }}
                          className="statisticButton tooltip-trigger"
                          alt="userOfCompany"
                          src={trend_ico}
                        ></img>
                        <div style={{
                          top: "0vh",
                          right: "2vw"
                        }} className="tooltip-content">Statistics by Service Provider</div>
                      </div> */}
                    </td>
                    <td style={{ width: "0%" }}>
                      {isDeletePopUp === customer.id && <PopUpAlert key={"pop-up" + index} text={"Do you wanna delete " + customer.name + " ?"} func={DeleteCompany} setIsPopUp={setIsDeletePopUp} body={{ id: customer.id }} ></PopUpAlert>}
                    </td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>

        <div
          className={
            statisticOfCompanyComponent === true
              ? "statistic-parent"
              : "statistic-parent statistic-parent-side"
          }
        >
          <img onClick={() => {
            setStatisticOfCompanyComponent(
              !statisticOfCompanyComponent
            );
          }} src={right_arrow_ico} className="rigth-arrow-statistics"></img>
          <div className="statistic-container">
            <div className="chart-container">
              <label>SLA/OLA</label>
              <div className="chart-parent">
                <OlaSlaPieChart></OlaSlaPieChart>
              </div>
            </div>

            <div className="chart-container">
              <label>Succes SLA Percentage</label>
              <div className="chart-parent">
                <GaugeStatistic value={0.75}></GaugeStatistic>
              </div>
            </div>

            <div className="chart-container">
              <label>Total Sla Percentages</label>
              <div className="chart-parent">
                <RoseStatistic></RoseStatistic>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
