import React, { useContext, useState } from "react";
import { LoginContext } from "./LoginContext";
import { useNavigate } from "react-router-dom";
import { MainContext } from "./MainContext";

export const OrganizationContext = React.createContext();

const OrganizationContextProvider = (props) => {
    const { token, user, roles } = useContext(LoginContext)
    const navigate = useNavigate();
    const { AddAlert, setAlertList } = useContext(MainContext);
    const [treeData, setTreeData] = useState()
    const [selectedOrganizationGroup, setSelectedOrganizationGroup] = useState()
    const [organizationGroupsList, setOrganizationGroupList] = useState()
    const [newOrganizationGroup, setNewOrganizationGroup] = useState(false)
    const [organizationGroupName, setOrganizationGroupName] = useState("")
    const [organizationNameToCreate, setOrganizationNameToCreate] = useState("")
    const [positionNameToCreate, setPositionNameToCreate] = useState("")
    const [parentOrganizationIdToAddDepartment, setParentOrganizationIdToAddDepartment] = useState(false)
    const [parentOrganizationIdToAddPosition, setParentOrganizationIdToAddPosition] = useState(false)
    const [parentPositionIdToAdd, setParentPositionIdToAdd] = useState(false)
    const [isDeleteOrganizationPopUp, setIsDeleteOrganizationPopUp] = useState(false)
    const [isDeletePositionPopUp, setIsDeletePositionPopUp] = useState(false)
    const [showPosition, setShowPosition] = useState(false)
    const [idOfClickedDepartment, setIdOfClickedDepartment] = useState()
    const [isShowUsers, setIsShowUsers] = useState(false)
    const [isShowAgreements, setIsShowAgreements] = useState(false)
    const [userList, setUserList] = useState([])
    const [okrList, setOkrList] = useState([])
    const [departmentOrPositionName, setDepartmentOrPositionName] = useState("")
    const [initialDepth, setInıtıalDepth] = useState("10")
    const [clickedEditDepartment, setClickedEditDepartment] = useState()

    function ResetContext(){
        setTreeData()
        setSelectedOrganizationGroup()
        setOrganizationGroupList()
        setNewOrganizationGroup(false)
        setOrganizationGroupName("")
        setOrganizationNameToCreate("")
        setParentOrganizationIdToAddDepartment(false)
        setParentOrganizationIdToAddPosition(false)
        setParentPositionIdToAdd(false)
        setIsDeleteOrganizationPopUp(false)
        setIsDeletePositionPopUp(false)
        setShowPosition(false)
        setIdOfClickedDepartment()
        setIsShowUsers(false)
        setIsShowAgreements(false)
        setUserList([])
        setOkrList([])
        setDepartmentOrPositionName("")
    }

    function ConvertPositionsDataToTree(data) {
        function FilterItemsByParentId(parentId) {
            let result = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].parentPositionId === parentId) {
                    result.push(
                        {
                            name: data[i].name,
                            attributes: {
                                type: "position",
                                position: data[i],
                                department: "",
                                organization: ""
                            },
                            children: GetChildrens(data[i].id)
                        }
                    );
                }
            }
            return result;
        }

        function GetChildrens(parentId) {
            let childrens = FilterItemsByParentId(parentId)
            return childrens;
        }

        const roots = data.filter(position => position.parentPositionId === null);

        function BuildTreeFromRoot(root) {
            return {
                name: root.name,
                attributes: {
                    type: "position",
                    position: root,
                    department: "",
                    organization: ""
                },
                children: GetChildrens(root.id)
            };
        }
        if (roots.length > 0) {
            // Her bir kök pozisyon için ayrı bir ağaç oluşturup birleştiriyoruz.
            const trees = roots.map(root => BuildTreeFromRoot(root));
            return trees; // Ana birleştirilmiş ağacı döndürüyoruz.
        }
        return null
    }

    function ConvertToTree(data) {
        function FilterItemsByParentId(parentId) {
            let result = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].parentOrganization?.id === parentId) {
                    result.push(
                        {
                            name: data[i].name,
                            attributes: {
                                type: "department",
                                position: "",
                                department: "",
                                organization: data[i]
                            },
                            children: GetChildrens(data[i].id)
                        }
                    );
                }
            }
            return result;
        }

        function GetChildrens(parentId) {
            let childrens = FilterItemsByParentId(parentId)
            return childrens;
        }

        const root = data.find(organization => organization?.parentOrganization === null)
        
        if (root) {
            let tree = {
                name: root.name,
                attributes: {
                    type: "department",
                    position: "",
                    department: "",
                    organization: root
                },
                children: GetChildrens(root.id)
            }
            return tree
        }
        return null
    }
    function FindOrganizationNode(node, targetOrgId) {
        if (node.attributes && node.attributes.organization && node.attributes.organization.id === targetOrgId) {
            return node;
        }
        for (var i = 0; i < node.children.length; i++) {
            var result = FindOrganizationNode(node.children[i], targetOrgId);
            if (result) {
                return result;
            }
        }

        return null;
    }

    function AddNewChildrens(jsonData, targetOrgId, newDepartment, showPosition) {//newDepartment is positions data
        let targetNode = FindOrganizationNode(jsonData, targetOrgId);
        if (targetNode) {
            if (showPosition) {//if true we will show positions
                if (targetNode?.children?.length > 0 && (targetNode.children[0]?.attributes?.type === "department" ||
                    targetNode?.tempChildrenForPositions?.length > 0 && targetNode.tempChildrenForPositions[0]?.attributes?.type === "position")) {//when children is department
                    targetNode.tempChildrenForPositions = targetNode.children;//to switch between department and positions
                    targetNode.children = newDepartment;
                }
                else {
                    targetNode.tempChildrenForPositions = targetNode.tempChildrenForPositions;//to switch between department and positions
                    targetNode.children = newDepartment;
                }
            }
            else {
                if (targetNode?.tempChildrenForPositions?.length > 0 && (targetNode.tempChildrenForPositions[0]?.attributes?.type === "department" ||
                    targetNode?.children?.length > 0 && targetNode.children[0]?.attributes?.type === "position")) {//when tempChildrenForPositions is department
                    targetNode.children = targetNode.tempChildrenForPositions;//to switch between department and positions
                    targetNode.tempChildrenForPositions = newDepartment;
                }
                else {
                    targetNode.children = targetNode.children;//to switch between department and positions
                    targetNode.tempChildrenForPositions = newDepartment;
                }
            }
            setTreeData((prevData) => ({ ...prevData }));
        }
    }
    function GetPositionOfOrganization(body, showPosition) {
        if (roles.some(role => role === "PositionRead")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_POSITION, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Get position failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                    const convertedPositionData = ConvertPositionsDataToTree(data.positionDtos)
                    if (convertedPositionData) {
                        AddNewChildrens(treeData, body.organizationId, convertedPositionData, showPosition)//if showPosition true we will show position on tree
                        AddAlert("success", "Get position successful")
                    }
                    else {
                        AddNewChildrens(treeData, body.organizationId, [], false)//if showPosition false we will show department on tree
                        setShowPosition(false)
                        AddAlert("warning", "Positions is empty")
                    }
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function DeletePosition(body) {
        if (roles.some(role => role === "PositionDelete")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_POSITION, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body.body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Delete position failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                    GetPositionOfOrganization({
                        "organizationId": body.organizationId,
                        "pageNumber": 1,
                        "pageSize": 999
                    }, true)
                    AddAlert("success", "Delete position successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function CreatePosition(body) {
        if (roles.some(role => role === "PositionCreate")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_POSITION, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Create organization failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                    GetPositionOfOrganization({
                        "organizationId": body.organizationId,
                        "pageNumber": 1,
                        "pageSize": 999
                    }, true)
                    setPositionNameToCreate("")
                    setParentOrganizationIdToAddPosition(false)
                    setParentPositionIdToAdd(false)
                    AddAlert("success", "Create position successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function DeleteOrganization(body) {
        if (roles.some(role => role === "OrganizationDelete")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_ORGANIZATION, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Delete organization failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                        GetOrganizationGroup({
                            pageNumber: 1,
                            pageSize: 999,
                            companyId: user?.company?.id
                        })
                
                    AddAlert("success", "Delete organization successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function CreateOrganization(body) {
        if (roles.some(role => role === "OrganizationCreate")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_ORGANIZATION, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Create organization failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                    
                        GetOrganizationGroup({
                            pageNumber: 1,
                            pageSize: 999,
                            companyId: user?.company?.id
                        })
                    
                    setOrganizationNameToCreate("")
                    setParentOrganizationIdToAddDepartment(false)
                    AddAlert("success", "Create organization successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }


    function CreateOrganizationGroup(body) {
        if (roles.some(role => role === "OrganizationCreate")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_CREATE_ORGANIZATION_GROUP, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Create organization group failed")
                throw res;
            })
            .then((data) => {
                if (!data.result.isSuccessful) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.isSuccessful) {
                    setOrganizationGroupName("")
                    setNewOrganizationGroup(false)
                    
                        GetOrganizationGroup({
                            pageNumber: 1,
                            pageSize: 999,
                            companyId: user?.company?.id
                        })
                    
                    AddAlert("success", "Create organization group successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function GetOrganizationGroup(body) {
        if (roles.some(role => role === "OrganizationRead")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_GROUP, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Get organization failed")
                throw res;
            })
            .then((data) => {
                if (data.result.statusCode === 400) {
                    AddAlert("error", data.result.message)
                    return data;
                }
                if (data.result.statusCode === 200) {
                    setOrganizationGroupList(data.organizationGroups)
                    if (data.organizationGroups.length > 0) {
                        if (selectedOrganizationGroup === undefined) {
                            setSelectedOrganizationGroup(data.organizationGroups[0]?.id)
                            setTreeData(ConvertToTree(data.organizationGroups[0]?.organizations))
                        }
                    }
                    AddAlert("success", "Get organization successful")
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    
    }

    function UpdateOrganization(body) {
        if (roles.some(role => role === "OrganizationEdit")) {

        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_UPDATE_ORGANIZATION, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Get organization failed")
                throw res;
            })
            .then((data) => {
                if (data.result.statusCode === 400) {
                    AddAlert("error", data.result.message)
                    GetOrganizationGroup({
                        pageNumber: 1,
                        pageSize: 999,
                        companyId: user?.company?.id
                    })
                    return data;
                }
                if (data.result.statusCode === 200) {
                    setClickedEditDepartment()
                    AddAlert("success", "Update organization successful")
                    GetOrganizationGroup({
                        pageNumber: 1,
                        pageSize: 999,
                        companyId: user?.company?.id
                    })
                    return data;
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }
    
    function GetAccounts(body) {
        if (roles.some(role => role === "UserRead")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_ACCOUNTS, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Get users failed")
                throw res;
            })
            .then((data) => {
                setUserList(data.accounts)
                AddAlert("success", "Get users successful")
                return data;
            })
            .catch((error) => {
                setUserList([])
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function GetOkr(params) {
        const urlParams = [
            params?.OwnerAccountId && `OwnerAccountId=${params?.OwnerAccountId}`,
            params?.OwnerOrganizationId && `OwnerOrganizationId=${params?.OwnerOrganizationId}`,
          ].filter(Boolean).join("&");
          
        if (roles.some(role => role === "OkrRead")) {
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_OKR_BY_OWNER+"?"+ urlParams, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            }
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                AddAlert("error", "Get OKRs failed")
                throw res;
            })
            .then((data) => {
                setOkrList(data.sla)
                AddAlert("success", "Get OKRs successful")
                return data;
            })
            .catch((error) => {
                setOkrList([])
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }


    function GetCompany(body) {
        return new Promise((resolve) => {
          fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + token,
              "TenantId": "slm",
            },
            body: JSON.stringify(
              body
            ),
          })
            .then((res) => {
              if (res.ok && res.status === 200) {
                return res.json();
              }
              else if (res.status === 401) {
                setAlertList([])
                localStorage.clear();
                navigate("/login");
              }
              throw res;
            })
            .then((data) => {
              resolve(data?.companies);
            })
            .catch((error) => {
              resolve([])
              console.log(error);
            });
        })
      }
    

    return (
        <OrganizationContext.Provider value={{
            treeData,
            setTreeData,
            GetOrganizationGroup,
            selectedOrganizationGroup,
            setSelectedOrganizationGroup,
            organizationGroupsList,
            setOrganizationGroupList,
            ConvertToTree,
            newOrganizationGroup,
            setNewOrganizationGroup,
            organizationGroupName,
            setOrganizationGroupName,
            CreateOrganizationGroup,
            organizationNameToCreate,
            setOrganizationNameToCreate,
            CreateOrganization,
            DeleteOrganization,
            GetPositionOfOrganization,
            FindOrganizationNode,
            CreatePosition,
            DeletePosition,
            parentOrganizationIdToAddDepartment,
            setParentOrganizationIdToAddDepartment,
            parentPositionIdToAdd,
            setParentPositionIdToAdd,
            isDeleteOrganizationPopUp,
            setIsDeleteOrganizationPopUp,
            isDeletePositionPopUp,
            setIsDeletePositionPopUp,
            positionNameToCreate,
            setPositionNameToCreate,
            parentOrganizationIdToAddPosition,
            setParentOrganizationIdToAddPosition,
            showPosition,
            setShowPosition,
            idOfClickedDepartment,
            setIdOfClickedDepartment,
            isShowUsers,
            setIsShowUsers,
            userList,
            setUserList,
            GetAccounts,
            departmentOrPositionName,
            setDepartmentOrPositionName,
            initialDepth,
            setInıtıalDepth,
            ResetContext,
            clickedEditDepartment,
            setClickedEditDepartment,
            UpdateOrganization,
            isShowAgreements,
            setIsShowAgreements,
            GetOkr,
            okrList,
            setOkrList,
            GetCompany
        }}>
            {props.children}
        </OrganizationContext.Provider>
    );
}

export default OrganizationContextProvider;