import React, { useContext, useState } from "react";
import { MainContext } from "./MainContext";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LoginContext";

export const CustomersContext = React.createContext();

const CustomersContextProvider = (props) => {
    const { setAlertList, AddAlert } = useContext(MainContext);
    const navigate = useNavigate();
    const { token, user,roles } = useContext(LoginContext)

    const [usersOfCompanyComponent, setUsersOfCompanyComponent] = useState(false);
    const [statisticOfCompanyComponent, setStatisticOfCompanyComponent] = useState(false);
    const [customers, setCustomer] = useState([]);
    const [accountCompany, setAccountCompany] = useState("");
    const [isDeletePopUp, setIsDeletePopUp] = useState(false)
    const [selectedCompanyGroup, setSelectedCompanyGroup] = useState()
    const [companyGroupList, setCompanyGroupList] = useState([])

    function ResetContext(){

    }

    function IsPossibleDeleteCompany(companyId){
        fetch(process.env.REACT_APP_BASE_URL_SLM + process.env.REACT_APP_GET_ALL_SLA_BY_COMPANY_ID_AND_COMPANY_GROUP_ID+  "?CompanyId="+companyId+"&Deleted=false&Page=1&PageSize=999", {
          method: "GET",
          headers: {
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
          },
        })
          .then((res) => {
            if (res.ok && res.status === 200) {
              return res.json();
            }
            else if (res.status === 401) {
              setAlertList([])
              localStorage.clear();
              navigate("/login");
            }
            throw res.json();
          })
          .then((data) => {
            if (data?.totalSlaCount === 0) {
              setIsDeletePopUp(companyId)
              return data;
            }
            else {
              AddAlert("warning", "This company can not delete")
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }

    function GetAllCompanyGroup(ownerCompanyId) {
        if (roles.some(role => role === "CompanyGroupRead")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_GET_ORGANIZATION_COMPANY_GROUP, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(
                {
                    ownerCompanyId: ownerCompanyId,
                    pageNumber: 1,
                    pageSize: 9999,                
                    includeCompanies: true,
                }
            ),
        })
            .then((res) => {
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res;
            })
            .then((data) => {
                if (data?.companyGroups.length > 0) {
                    setSelectedCompanyGroup(data?.companyGroups[0])
                    setCompanyGroupList(data?.companyGroups);
                }
                else {
                    setSelectedCompanyGroup()
                    setCompanyGroupList([])
                }
            })
            .catch((error) => {
                setCompanyGroupList([])
                setSelectedCompanyGroup()
                console.log(error);
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }

    function DeleteCompany(body) {
        if (roles.some(role => role === "CompanyDelete")) {
        fetch(process.env.REACT_APP_BASE_URL_AUTH + process.env.REACT_APP_DELETE_ORGANIZATION_COMPANY, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token,
                "TenantId": "slm",
            },
            body: JSON.stringify(body)
        })
            .then((res) => {
                if(user?.company?.id){
                    GetAllCompanyGroup(user.company.id)
                }
                if (res.ok && res.status === 200) {
                    return res.json();
                }
                else if (res.status === 401) {
                    setAlertList([])
                    localStorage.clear();
                    navigate("/login");
                }
                throw res.json();
            })
            .then((data) => {
                if (data.result.isSuccessful) {
                    AddAlert("success", data.result.message)
                    return data;
                }
                else {
                    AddAlert("error", data.result.message)
                }
            })
            .catch((error) => {
                AddAlert("error", "Delete Service Provider is Failed")
                console.log(error)
            });
        }
        else{
          AddAlert("warning", "You do not have authorization for this operation.")
        }
    }



    return (
        <CustomersContext.Provider value={{
            usersOfCompanyComponent,
            setUsersOfCompanyComponent,
            statisticOfCompanyComponent,
            setStatisticOfCompanyComponent,
            customers,
            setCustomer,
            accountCompany,
            setAccountCompany,
            isDeletePopUp,
            setIsDeletePopUp,
            DeleteCompany,
            GetAllCompanyGroup,
            selectedCompanyGroup,
            setSelectedCompanyGroup,
            companyGroupList,
            setCompanyGroupList,
            IsPossibleDeleteCompany
        }}>
            {props.children}
        </CustomersContext.Provider>
    );
}

export default CustomersContextProvider;